import React from "react";
import Forms from "./Forms";

const Contact: React.FC = () => {
  return(
    <div>
      <Forms />
    </div>
  )
}

export default Contact;